<template>
  <div class="nav1">
    <!--    <div class="nav1-center">
          <div class="nav1-center-left" style="cursor: pointer">
            <img class="nav1-center-left-img" @click="_clickSy" :src="navobj.company">
            <img class="nav1-center-left-img2" @click="_clickSy" :src="navobj.company2">
          </div>
          <div class="nav1-center-center">
            <div :class="{'frame':v.menuselect==1}"
                 :key="i"
                 v-for="(v,i) in navobj.navmenu" @click="handlerouter(v)">
              <div class="nav1-center-center-menu"
                   :class="selected==v.router?'select-nav1-center-center-menu':'nav1-center-center-menu'">
                {{ v.title }}
              </div>

              <div class="item">
                <div class="small-sjx"></div>
                <div class="item_i" style="box-shadow: 0 0 9px rgba(0,0,0,0.4)">

                  <div style="background-color: #fff;  padding: 10px;">
                    <div
                        style="cursor:pointer;padding: 8px 0;display: flex;flex-direction: row"
                        :style="key==0?'border-top: 0':'border-top: 1px  solid #e5e5e5'" :key="key"
                        v-for="(item,key) in v.child" @click.stop="_clickJump(item)">
                      <div style="flex: 1;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                        {{ item.name }}
                      </div>
                      <div style="margin-left: 10px">></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="nav1-center-right">
            <div
                style="font-size: 16px;border: 2px solid #1BBDA9;background-color: #1BBDA9;border-radius: 20px;display: flex;align-items: center;overflow: hidden">
              <input v-model="search" style="width: 250px;border: 0;outline: none;padding: 10px" placeholder="请输入项目"/>
              <div style="width: 100px;text-align: center;font-size: 16px;color: white" @click="_clickSearch">搜索</div>
            </div>
          </div>
          <div class="frame" v-if="searchArray.length>0">
            <div class="item" style="display: flex;left: -370px;top: -10px">
              <div class="small-sjx"></div>
              <div class="item_i" style="box-shadow: 0 0 9px rgba(0,0,0,0.4)">

                <div style="background-color: #fff;  padding: 10px;">
                  <div
                      style="cursor:pointer;padding: 8px 0;display: flex;flex-direction: row"
                      :style="key==0?'border-top: 0':'border-top: 1px  solid #e5e5e5'" :key="key"
                      v-for="(item,key) in searchArray"
                      @click.stop="_clickJump(item)">
                    <div style="flex: 1;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">

                      {{ item.name }}

                    </div>
                    <div style="margin-left: 10px">></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>-->

    <div style="display: flex;flex-direction: row;padding: 23px ">
      <div class="nav1-center-left" style="cursor: pointer;margin-right: 500px">
        <img class="nav1-center-left-img" @click="_clickSy" :src="navobj.company">
        <img class="nav1-center-left-img2" @click="_clickSy" :src="navobj.company2">
      </div>
      <div class="nav1-center-right">
        <div
            style="font-size: 16px;border: 2px solid #1BBDA9;background-color: #1BBDA9;border-radius: 20px;display: flex;align-items: center;overflow: hidden">
          <input v-model="search" style="width: 250px;border: 0;outline: none;padding: 10px" placeholder="请输入项目"/>
          <div style="cursor: pointer;width: 100px;text-align: center;font-size: 16px;color: white" @click="_clickSearch">搜索</div>
        </div>
      </div>
      <div class="frame" v-if="searchArray.length>0">
        <div class="item" style="display: flex;left: -357px;top: 46px">
<!--          <div class="small-sjx"></div>-->
          <div class="item_i" style="box-shadow: 0 0 9px rgba(0,0,0,0.4)">

            <div style="background-color: rgba(255,255,255,0.9);  padding: 10px;">
              <div
                  style="cursor:pointer;padding: 8px 0;display: flex;flex-direction: row"
                  :style="key==0?'border-top: 0':'border-top: 1px  solid #9e9e9e'" :key="key"
                  v-for="(item,key) in searchArray"
                  @click.stop="_clickJump(item,4)">
                <div style="flex: 1;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">

                  {{ item.name }}

                </div>
<!--                <div style="margin-left: 10px">></div>-->
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="nav1-center" >

      <div class="nav1-center-center" style="width: 1000px;font-size: 17px">
        <div :class="{'frame':v.menuselect==1}"
             :key="i"
             v-for="(v,i) in navobj.navmenu" @click="handlerouter(v)">
          <div class="nav1-center-center-menu"
               :class="selected==v.router?'select-nav1-center-center-menu':'nav1-center-center-menu'">
            {{ v.title }}
          </div>

          <div class="item" style="color: #222222">
<!--            <div class="small-sjx"></div>-->
            <div class="item_i" style="box-shadow: 0 0 9px rgba(0,0,0,0.4)">

              <div style="background-color: rgba(255,255,255,0.9);  padding: 10px;">
                <div
                    style="cursor:pointer;padding: 8px 0;display: flex;flex-direction: row"
                    :style="key==0?'border-top: 0':'border-top: 1px  solid #e5e5e5'" :key="key"
                    v-for="(item,key) in v.child" @click.stop="_clickJump(item,item.type)">
                  <div style="flex: 1;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                    {{ item.name }}
                  </div>
<!--                  <div style="margin-left: 10px">></div>-->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
//公司logo图
import company from '@/images/company.png?123';
import company2 from '@/images/company2.png?123';
//电话图标
import phoneicon from '@/images/phoneicon.png';
import {getXmzx} from "@/api/home";

export default {
  data() {
    return {
      search: '',
      searchArray: [],
      navobj: {
        //公司logo
        company,
        company2,
        //电话
        phone: {
          //号码
          phonenumber: '0754-88889956',
          //图标
          phoneicon
        },
        //导航栏菜单
        navmenu: [
          {
            //菜单文字
            title: '首页',
            //路由
            router: '/',
            //选中状态
            menuselect: 0,
          },
          {
            title: '关于我们',
            router: '/about?type=1',
            menuselect: 1,
          },
          {
            title: '项目介绍',
            router: '/about?type=4',
            menuselect: 1,
            child: []

          },
          {
            title: '新闻资讯',
            router: '/about?type=5',
            menuselect: 1,
            child: []
          },
          {
            title: '人才招聘',
            router: '/about?type=2',
            menuselect: 1,
            child: []
          },
          {
            title: '联系我们',
            router: '/contact?type=1',
            menuselect: 0,
          },

        ]
      },
      selected: '/'
    }
  },
  created() {


    let name = window.location.pathname;

    if (window.location.search) {

      name = name + window.location.search;
    }

    this.selected = name;

    this._getXmzx();

  },
  methods: {


    _clickSearch() {


      let searchArray = [];

      this.navobj.navmenu[2].child.forEach((v) => {

        if (v.name.indexOf(this.search) != -1) {

          searchArray.push(v);

        }

      });

      this.searchArray = searchArray;

    },

    _clickSy() {

      this.$router.push({path: '/'})

    },
    _clickJump(e,type) {

      if (type == 4||type==1) {

        this.$router.push({path: 'about?type='+type+'&id=' + e.id});

      }else{
        this.$router.push({path: 'detail?id=' + e.id});

      }


    },
    _getXmzx() {


      getXmzx().then(res => {

        this.navobj.navmenu[4].child = res['rc'];
        this.navobj.navmenu[3].child = res['xw'];
        this.navobj.navmenu[2].child = res['xm'];
        this.navobj.navmenu[1].child = res['gy'];


      });


    },

    //跳转路由
    handlerouter(e) {

      if (e.router == this.selected) {

        return;
      }

      this.selected = e.router;
      this.$router.push({path: e.router});


    },
  },
  watch: {
    search(e) {

      if (e.length == 0) {
        this.searchArray = [];
      }

    }
  }
}
</script>

<style scoped>
.nav1 {
  width: 100%;
  min-width: 1200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}

.nav1-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  color: #222222;
  font-size: 18px;
  background-color: #1BBDA9;
}

.nav1-center-left {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
}

.nav1-center-left-img {
  width:65px;
  display: block;
}

.nav1-center-left-img2 {
  width: 140px;
  height: 30px;
  margin-top: 20px;
  margin-left: 10px;
  display: block;
}

.nav1-center-center {
  display: flex;
  flex-direction: row;
  width: 450px;
  padding: 10px 0;
  color: white;
  justify-content: space-between;
}

.nav1-center-center-menu, .select-nav1-center-center-menu {
  padding: 2px 10px;
  border-radius: 3px;
}

.nav1-center-center-menu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.frame {
  position: relative;
}

.frame:hover .item {
  display: flex;


}

.small-sjx {
  width: 0;
  position: absolute;
  top: 1px;
  height: 0;
  margin-left: 10px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #ffffff;
}

.item {
  display: none;
  z-index: 100;
  flex-direction: column;
  left: 0;
  top: 20px;
  padding-top: 16px;
  position: absolute;
  font-size: 15px;
  background-color: unset;
}

.item_i {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  min-width: 90px;
  font-size: 15px;
  text-align: center;
  background-color: unset;
  border-radius: 5px;
  overflow: hidden;
}

.select-nav1-center-center-menu {
  cursor: default;
  background-color: var(--line-height-color);
  color: white;
}

.nav1-center-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav1-center-right-left {
  width: 18px;
  height: 18px;
  margin-right: 11px;
}

.nav1-center-right-left-img {
  width: 100%;
  height: 100%;
  display: block;
}

.nav1-center-right-right {
  color: var(--line-height-color);
  font-weight: var(--phone-font-weight-bold);
}
</style>