import axios from 'axios'
// import {Message} from 'element-ui'
// import {getToken, removeToken} from '@/utils/auth'
// import router from '@/router'
// import {MessageBox} from 'element-ui'
// import store from '../store';
import {getBaseUrl} from "./baseUrl";

let baseUrl = getBaseUrl();

// create an axios instance
const service = axios.create({
    // baseURL: 'http://www.youwolife.cn/public/index.php/admin/api/v1/', // api的base_url
    // baseURL: 'http://xiaoshou.com/index.php/admin/api/v1/', // api的base_url
    baseURL: baseUrl, // api的base_url
    timeout: 1000 * 60, // request timeout


});



// request interceptor
service.interceptors.request.use(config => {
    // Do something before request is sent
    //   config.headers['Authorization'] =getToken() // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改

    // 对 data 进行任意转换处理

    if (config.data === undefined) {


        config.data = {}
    }


    // config.headers['Authorization'] = getToken();


    // if (config['method'] === 'get') {
    //
    //     if (config['url'] !== 'community'&&config['url'] !== 'contract') {
    //         config.params['park_id'] = store.state.park_id;
    //     }
    //
    // } else {
    //
    //
    //
    //     config.data['park_id'] = store.state.park_id;
    //
    //
    // }


    return config
}, error => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
    response => {


        return response.data;
    },
    /**
     * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
     * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
     */
        //  const res = response.data;
        //     if (res.code !== 20000) {
        //       Message({
        //         message: res.message,
        //         type: 'error',
        //         duration: 5 * 1000
        //       });
        //       // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
        //       if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        //         MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
        //           confirmButtonText: '重新登录',
        //           cancelButtonText: '取消',
        //           type: 'warning'
        //         }).then(() => {
        //           store.dispatch('FedLogOut').then(() => {
        //             location.reload();// 为了重新实例化vue-router对象 避免bug
        //           });
        //         })
        //       }
        //       return Promise.reject('error');
        //     } else {
        //       return response.data;
        //     }
    error => {



        // console.log('err:' + error)// for debug

        // console.log(error.response);


       /* if (error.response == undefined) {

            Message({
                message: '访问服务器超时，请稍后再试',
                type: 'error',
                duration: 5 * 1000
            });
            return Promise.reject(error)
        }


        const errorCode = error.response.status;


        if (errorCode == 403 && (error.response.data.errorCode === 10002 || error.response.data.errorCode === 10001 || error.response.data.errorCode === 50000)) {


            MessageBox.alert('登录过期，请重新登录', '登录过期', {
                confirmButtonText: '确定',
                showClose: false,
                callback: action => {


                    store.state.permissions = [];
                    store.state.isLoading = false;

                    removeToken();
                    router.push({
                        path: '/login'
                    });

                }
            });


            return;

            // return Promise.reject(error)


        }

        if (error.response.data.errorCode) {


            Message({
                message: error.response.data.msg,
                type: 'error',
                duration: 5 * 1000
            });*/

            // return Promise.reject(error)


        // }

        //



        return Promise.reject(error)
    })

export default service
