import request from '../utils/request'


export function getSwiperSelect(params) {

    return request({

        url:'swiper/select'
        ,method: 'get'
        ,params

    })


}

export function getHomeProject(params) {

    return request({

        url:'home/project'
        ,method: 'get'
        ,params

    })


}

export function getXmzx(params) {

    return request({

        url:'xmzx'
        ,method: 'get'
        ,params

    })


}

export function createLy(data) {

    return request({

        url:'create_ly'
        ,method: 'post'
        ,data

    })


}
