export function getBaseUrl() {

    let baseUrl = '';

    if (process.env.NODE_ENV === 'development') {
        // baseUrl = 'http://192.168.100.8:8017/index.php/';
        baseUrl = 'https://api.ouruihealth.com/index.php/';

    } else {
        baseUrl = 'https://api.ouruihealth.com/index.php/';

    }


    return baseUrl;

}

export function getImgBaseUrl() {

    let baseUrl = '';

    if (process.env.NODE_ENV === 'development') {
        // baseUrl = 'http://192.168.100.8:8017/';
        baseUrl = 'https://api.ouruihealth.com/';

    } else {
        baseUrl = 'https://api.ouruihealth.com/';

    }


    return baseUrl;

}




