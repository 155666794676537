<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-top-left">
        <img class="footer-top-left-img" :src="footerobj.company1" alt="">
      </div>
      <div style="flex: 1">

      <div class="footer-top-center">
        <!--        <div class="footer-top-center-unit" v-for="(v,i) in footerobj.footerlist" :key="i">
                  <div class="footer-top-center-unit-top" style="cursor: pointer;" @click="_clickJp(v.router)">
                    {{ v.title }}
                  </div>
                  <div class="footer-top-center-unit-bottom" @click="_ijp(c)"
                       style="width: 100px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"
                       v-for="(c,d) in v.chilren" :key="d">
                    {{ c.name }}
                  </div>
                  <div class="footer-top-center-unit-bottom frame" v-if="v.child&&v.child.length>0">

                    <div>...</div>

                    <div class="item">
                      <div class="small-sjx"></div>
                      <div class="item_i" style="box-shadow: 0 0 9px rgba(0,0,0,0.4)">

                        <div style="background-color: #fff;  padding: 10px;">
                          <div
                              style="cursor:pointer;padding: 8px 0;display: flex;flex-direction: row;color: #000;font-weight: unset"
                              :style="key==0?'border-top: 0':'border-top: 1px  solid #e5e5e5'" :key="key"
                              v-for="(item,key) in v.child" @click.stop="_clickJump(item)">
                            <div style="flex: 1;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                              {{ item.name }}
                            </div>
                            <div style="margin-left: 10px">></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>-->
        <div>
          <div style="cursor: pointer;font-size: 15px" @click="_clickJp('about?type=1')">关于我们</div>
          <div></div>
        </div>
        <div>|</div>
        <div>
          <div style="cursor: pointer;font-size: 15px" @click="_clickJp('about?type=4')">项目介绍</div>
<!--          <div style="display: flex;flex-direction: row;flex-wrap: wrap;width: 130px;margin-top: 10px;line-height: 2">
            <div style="width: 65px;cursor: pointer" @click="_clickJp('detail?id='+v.id)" :key="k"
                 v-for="(v,k) in xmjs">
              <div>{{ v.name }}</div>
            </div>
          </div>-->
        </div>
        <div>|</div>

        <div>
          <div style="cursor: pointer;font-size: 15px" @click="_clickJp('about?type=5')">新闻资讯</div>
<!--          <div style="display: flex;flex-direction: column;flex-wrap: wrap;margin-top: 10px;line-height: 2">
            <div style="display: flex;flex-direction:row;cursor: pointer;" @click="_clickJp('detail?id='+v.id)" :key="k"
                 v-for="(v,k) in xwzx">
              <div style="width: 120px;white-space:nowrap;
overflow:hidden;
text-overflow:ellipsis;">{{ v.name }}
              </div>
              <span style="font-size: 12px">【详情】</span>
            </div>
          </div>-->
        </div>
        <div>|</div>

        <div>
          <div style="cursor: pointer;font-size: 15px" @click="_clickJp('about?type=2')">人才招聘</div>
          <div></div>
        </div>


      </div>
        <div class="footer-bottom" style="margin-top: 20px">
          <div class="footer-bottom-addressobj">
            <div class="footer-bottom-addressobj-label">{{ footerobj.addressobj.label }}<span>&nbsp;</span></div>
            <div class="footer-bottom-addressobj-content">{{ footerobj.addressobj.content }}</div>
          </div>

          <!--            <div>&nbsp;/</div>-->
          <div class="footer-bottom-mailobj">
            <div class="footer-bottom-mailobj-label">{{ footerobj.phoneobj.label }}<span>&nbsp;</span></div>
            <div class="footer-bottom-mailobj-content">{{ footerobj.phoneobj.content }}</div>
          </div>

        </div>
        <div @click="_jp" style="cursor: pointer;text-align: left;color: white;font-size: 12px;margin-top: 5px">粤ICP备2021106248号-1</div>

      </div>
      <div class="footer-top-right">
        <div class="footer-top-right-unit" v-for="(v,i) in footerobj.orcodelist" :key="i">
          <div class="footer-top-right-unit-top">
            <img class="footer-top-right-unit-top-img" :src="v.orcode" alt="">
          </div>
          <div class="footer-top-right-unit-bottom" style="font-size: 13px;line-height: 23px">
            {{ v.title }}
          </div>
        </div>
      </div>
    </div>

<!--    <div style="text-align: center;color: white;font-size: 12px;margin-top: 5px">
      {{ footerobj.phoneobj.label }}
      {{ footerobj.phoneobj.content }}
    </div>-->
    <div style="position: fixed;bottom: 100px;right: 20px;z-index: 9999">
      <div id="up" @click="_gd"
           style="cursor: pointer;text-decoration: none;opacity: 0;display: flex;flex-direction: column;justify-content: center;align-items: center;background-color: white;padding: 10px;box-shadow: 2px 1px 20px 0 #00000008;color: black">
        <i style="font-size: 30px" class="el-icon-arrow-up"></i>
        <div>回顶部</div>
      </div>
    </div>


    <div class="home-service">
      <div class="home-service-top">
        {{ serviceobj.title }}
      </div>
      <div class="home-service-center" @click="_clickLy">
        <div class="home-service-center-left">
          <img :src="serviceobj.servicecontent.chaticon" class="home-service-center-left-img" alt="">
        </div>
<!--        <div class="home-service-center-right">
          {{ serviceobj.servicecontent.content }}
        </div>-->
      </div>
      <div class="home-service-bottom">
        <div class="home-service-bottom-left">
          <img :src="serviceobj.servicecontent.phoneicon" class="home-service-bottom-left-img" alt="">
        </div>
        <div class="home-service-bottom-right">
          {{ serviceobj.servicecontent.phonenumber }}
        </div>
      </div>
    </div>


    <el-dialog :close-on-click-modal="false" title="留言" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="ly">
        <el-form-item label="留言" prop="content" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="form.content">
          </el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="mobile" :rules="[{ required: true, message: '请输入联系方式', trigger: 'blur' }]">
          <el-input v-model="form.mobile" placeholder="请输入联系方式"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="_qdLy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//公司logo1
import company1 from '/src/images/company1.png'
//公众号二维码
import orcode1 from '/src/images/orcode1.png'
//公众号二维码
import orcode2 from '/src/images/orcode2.png'
import {createLy, getXmzx} from "@/api/home";
import chaticon from "@/images/chaticon.png";
import phoneicon from "@/images/phoneicon.png";

export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        content: ''
        , mobile: ''
      },
      xmjs: [],
      xwzx: [],
      serviceobj: {
        title: '在线咨询客服',
        //在线客服咨询中间
        servicecontent: {
          //聊天图标
          chaticon,
          //立即留言
          content: '',
          //电话图标
          phoneicon,
          phonenumber: '0754-88889956',
        },
        link: '',
      },
      footerobj: {
        //公司logo1
        company1,
        footerlist: [

          {
            title: '关于我们',
            router: '/about?type=1'

          },
          {
            title: '项目介绍',
            chilren: [],
            child: []

          },
          {
            title: '新闻资讯',
            chilren: [],
            child: []

          },
          {
            title: '联系我们',
            router: '/contact?type=1'

          },
        ],
        addressobj: {
          label: '联系地址:',
          content: '广东省汕头市龙湖区长江路189号（即汕头口腔医院）',
        },
        phoneobj: {
          label: '客服热线:',
          content: '0754-88889956',
        },
        mailobj: {
          label: '电子邮箱:',
          content: 'storjk2@163.com',
        },
        orcodelist: [
          {
            orcode: orcode1,
            title: '公众号',
          },
          {
            orcode: orcode2,
            title: '客服号',
          },
        ],
      }
    }
  },
  created() {
    this._getXmzx();

    this.$nextTick(() => {


      document.querySelector('.scrolllayout').onscroll = function () {
        //为了保证兼容性，这里取两个值，哪个有值取哪一个
        //scrollTop就是触发滚轮事件时滚轮的高度
        var scrollTop = document.querySelector('.scrolllayout').scrollTop;


        // document.getElementById('up').style.opacity;
        document.getElementById('up').style.opacity = scrollTop * (1 / 655);

      }
    });

  },
  methods: {
    _createLy() {


      createLy(this.form).then(() => {


        this.form = {
          content: ''
          , mobile: ''
        };
        this.dialogFormVisible = false;
        this.$message.success('已留言，请耐心等待')


      });


    },

    _qdLy() {
      this.$refs['ly'].validate((valid) => {
        if (valid) {

          var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;//手机号码
          var isMob = /^0?1[3|4|5|8][0-9]\d{8}$/;// 座机格式
          if (isMob.test(this.form.mobile) || isPhone.test(this.form.mobile)) {

            this._createLy();

          } else {
            this.$message.warning('请输入正确的联系方式');
          }


        } else {
          return false;
        }
      });
    },
    _clickLy() {

      this.dialogFormVisible = true;
    },
    _gd() {
      document.querySelector('.scrolllayout').scrollTop = 0;
    },
    _clickJump(e) {

      this.$router.push({path: 'detail?id=' + e.id})
    },

    _getXmzx() {


      getXmzx().then(res => {

        let xm = JSON.parse(JSON.stringify(res['xm']));

        xm.splice(6, xm.length)
        // this.footerobj.footerlist[1].child = xm;


        this.xmjs = xm;

        let xw = JSON.parse(JSON.stringify(res['xw']));


        xw.splice(3, xw.length)

        this.xwzx = xw;


        /*if (res['xm'].length > 4) {
          let xm = JSON.parse(JSON.stringify(res['xm']));
          res['xm'] = xm.splice(0, 3)
          this.footerobj.footerlist[1].child = xm;


        }
        this.footerobj.footerlist[1].chilren = res['xm'];


        if (res['xw'].length > 4) {
          let xw = JSON.parse(JSON.stringify(res['xw']));
          res['xw'] = xw.splice(0, 3)
          this.footerobj.footerlist[2].child = xw;
        }

        this.footerobj.footerlist[2].chilren = res['xw'];*/

      });


    },

    _clickJp(e) {

      if (e) {


        this.$router.push({path: e});
      }

    },
    _ijp(e) {


      this.$router.push({path: 'detail?id=' + e.id})

    },
    _jp() {

      location.href = 'https://beian.miit.gov.cn/#/Integrated/index'

    }

  },

}
</script>

<style scoped>
.footer {


  font-family: 微软雅黑 !important;

  background: var(--line-height-color);
  width: 100%;
  min-width: 1200px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px!important;
}

.footer-top {
  width: 1100px;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-top-left {
  width: 88px;
  height: 116px;
  margin-right: 47px;
}

.footer-top-left-img {
  width: 100%;
  height: 100%;
}

.footer-top-center {
  width: 522px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 65px;
  color: white;
  font-size: 14px;
  margin-top: 5px;
}

.footer-top-center-unit-bottom {
  font-weight: 300;
  margin-top: 5px;
  cursor: pointer;
}

.footer-top-right {
  display: flex;
  flex-direction: row;
  width: 218px;
  justify-content: space-between;
  margin-top: 10px;
}

.footer-top-right-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 16px;
}

.footer-top-right-unit-top {
  width: 77px;
  height: 77px;
  margin-bottom: 5px;
}

.footer-top-right-unit-top-img {
  width: 100%;
  height: 100%;
  display: block;
}

.footer-bottom {
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 12px;
}

.footer-bottom-addressobj {
  display: flex;
  flex-direction: row;
}

.footer-bottom-phoneobj {
  display: flex;
  flex-direction: row;
}

.footer-bottom-mailobj {
  display: flex;
  flex-direction: row;
}


.f-item {

  flex: 1;
  text-align: center;
  cursor: pointer;

}


.frame {
  position: relative;
}

.frame:hover .item {
  display: flex;


}

.small-sjx {
  width: 0;
  position: absolute;
  bottom: -1px;
  height: 0;
  margin-left: 10px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #ffffff;
}

.item {
  display: none;
  z-index: 2;
  flex-direction: column;
  left: 0;
  bottom: 18px;
  padding-bottom: 8px;
  position: absolute;
  font-size: 15px;

}

.item_i {
  display: flex;
  flex-direction: column;
  width: 200px;
  font-size: 15px;
  background-color: unset;
  border-radius: 5px;

  overflow: hidden;

}

.el-button--primary {
  background-color: #1BBDA9;
  border-color: #1BBDA9;
}
</style>